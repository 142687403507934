import React from "react"
import PropTypes from "prop-types"
import loadable from "@loadable/component"
import Block from "./block.component"

const LOADABLE_COMPONENTS = {
  Warranties: {
    Component: loadable(() => import(`../../modules/information/warranty/warranties.component`)),
    isLoadable: () => true,
  },
  InterventionExample: {
    Component: loadable(() => import(`../../modules/navigation/interventionExemple.component`)),
    isLoadable: ({ interventionExamples }) => interventionExamples.length > 0,
  },
  ThreeSteps: {
    Component: loadable(() => import(`../../modules/information/threeSteps/threeSteps.component`)),
    isLoadable: () => true,
  },
  OwnerRenter: {
    Component: loadable(() => import(`../../modules/landing/home/ownerRenter.component`)),
    isLoadable: () => true,
  },
  ScrollerProduct: {
    Component: loadable(() => import(`../../modules/product/scroller/scrollerProduct.component`)),
    isLoadable: ({ products }) => products.length > 0,
  },
  ThreeBlockPicture: {
    Component: loadable(() => import(`../../modules/landing/threeBlockPicture.component`)),
    isLoadable: () => true,
  },
  VerifiedReviews: {
    Component: loadable(() => import(`../../modules/thirdParties/verifiedReviews.component`)),
    isLoadable: () => APP_CONFIG.featureFlags.verified_reviews,
  },
  Faq: {
    Component: loadable(() => import(`../../modules/faq/faq.component`)),
    isLoadable: ({ questions }) => questions.length > 0,
  },
  ProductDescription: {
    Component: loadable(() => import(`../../modules/product/productDescription.component`)),
    isLoadable: () => true,
  },
  SchemaCover: {
    Component: loadable(() => import(`../../modules/product/schemaCover.component`)),
    isLoadable: () => true,
  },
  ProductFinder: {
    Component: loadable(() => import(`../../modules/product/productFinder/productFinder.component`)),
    isLoadable: () => true,
  },
  WebsiteTrustedPartners: {
    Component: loadable(() => import(`../../modules/website/websiteTrustedPartners.component`)),
    isLoadable: ({ websiteTrustedPartners }) => websiteTrustedPartners.length > 0,
  },
  ProductBlock: {
    Component: loadable(() => import(`../../modules/product/productBlock.component`)),
    isLoadable: ({ products }) => products.length > 0,
  },
  BigTrustedPartner: {
    Component: loadable(() => import(`../../modules/website/bigTrustedPartner.component`)),
    isLoadable: () => true,
  },
  OfferCodeSection: {
    Component: loadable(() => import(`../../modules/landing/home/offerCodeSection.component`)),
    isLoadable: () => APP_CONFIG.featureFlags.hp_offer_code,
  },
}

export default function DynamicBlock ({ __component, children, ...props }) {
  const { Component = React.Fragment, isLoadable = () => true } = LOADABLE_COMPONENTS[__component] || {}

  if (!isLoadable(props)) {
    return null
  }

  return (
    <Block data-testid={arguments[0][`data-testid`] || undefined} Component={Component} {...props}>
      {children}
    </Block>
  )
}

DynamicBlock.propTypes = {
  __component: PropTypes.oneOf(Object.keys(LOADABLE_COMPONENTS)),
  children: PropTypes.any,
}
